import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';


import { Typography } from '@mui/material';
import Container from 'components/Container';
import ClassAssembly2024 from '../ClassAssembly2024';
import Archive2022 from '../ClassAssembly2023/Archive2022';

const ClassAssembly7E2024 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });


  const [config, setConfig] = useState({ base_image_url: '' });
useEffect(() => {
  fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
    .then(response => response.json())
    .then(data => setConfig(data))
    .catch(error => console.error('Error fetching config:', error));
}, []);

const p1 = `${config.base_image_url}/home/events-activities/class-assembly/7E/1.webp`;
const p2 = `${config.base_image_url}/home/events-activities/class-assembly/7E/2.webp`;
const p3 = `${config.base_image_url}/home/events-activities/class-assembly/7E/3.webp`;
const p4 = `${config.base_image_url}/home/events-activities/class-assembly/7E/4.webp`;
const p5 = `${config.base_image_url}/home/events-activities/class-assembly/7E/5.webp`;
const p6 = `${config.base_image_url}/home/events-activities/class-assembly/7E/6.webp`;
const p7 = `${config.base_image_url}/home/events-activities/class-assembly/7E/7.webp`;
const p8 = `${config.base_image_url}/home/events-activities/class-assembly/7E/8.webp`;
const p9 = `${config.base_image_url}/home/events-activities/class-assembly/7E/9.webp`;

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
        src: p2,
        source: p2,
        rows: 1,
        cols: 1,
      },
    {
      src: p3,
      source:p3,
      rows: 1,
      cols: 1,
    },
   
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
      src: p5,
      source: p5,
      rows: 1,
      cols: 1,
    },
    {
        src: p6,
        source: p6,
        rows: 1,
        cols: 1,
      },
    {
      src: p7,
      source:p7,
      rows: 1,
      cols: 1,
    },
   
    {
      src: p8,
      source: p8,
      rows: 1,
      cols: 1,
    },
    {
      src: p9,
      source: p9,
      rows: 1,
      cols: 1,
    },

  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      Staying Healthy
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
 Class: 7E      Date: January 25 to January 29, 2025

        </Typography>
        {/* <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
  “Health is the greatest wealth.”
<br/>
        </Typography> */}

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The Students of class 7E presented an impressive assembly on ‘Republic Day’ to mark this special day which is celebrated every year. The assembly mainly focussed on the life events of Dr.B R Ambedkar and how he used education to change the life of downtrodden people. Finally, he became the Chairman of the Constitution Committee to draft the Constitution of India.
 <br></br>
 The following day, the students presented a beautiful patriotic dance. On the third day, they performed a drama to show the struggle of the Father of Indian Constitution. It was followed by an informative quiz. 
  <br></br>
  The assembly concluded with a motivational dance and an interesting game of picture identification. The assembly was well appreciated by the Principal and the audience.
  <br></br>
        
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   "What is really needed to make democracy function is not knowledge of facts, but right education".
                                                                                                                                              - Mahatma Gandhi

   <br></br>
  
        </Typography>

    <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 200 : 100}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <ClassAssembly2024 />
             </Box>
             <Box marginBottom={4}>
               <Archive2022 />
             </Box>
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly7E2024;